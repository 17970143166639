//
// Switches
//

var Switch = function(){
    var $switch = $('.c-switch');

    $switch.on('click', function(e){
        var $switchInput = $(this).find('.c-switch__input');
        $(this).toggleClass('is-active');

        if ($switchInput.attr('checked')) {
            $switchInput.removeAttr('checked');
        } else {
            $switchInput.attr('checked', 'checked')
        }
            return false;
    });
};
